import React from "react";
import DocumentTitle from "../utils/DocumentTitle";

const Item = (props:any) => {
  DocumentTitle(props.searchTerm)
  return (
    <div>    
      <h2>{props.searchTerm} Pictures</h2>
    </div>
  );
};

export default Item;
