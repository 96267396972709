const Footer = () => {
    return (
        <div className="box-description negro-verde text_pie_three">
          <div className="grid">
              <div className="col">
                  <p className="text_pie">SUCURSAL CEDIS MERIDA</p>
                  <p className="text_pie_two">CALLE 22 #200 POR 19 Y 21 COLONIA MIRAFLORES<br />
                  CP. 97179<br/>
                  MÉRIDA, YUCATÁN<br/>
                  LUNES A SÁBADO DE 9:00-20:00 Hrs.<br />
                  </p>
                  <p className="text_pie">NUMEROS DE TELEFONO:</p>
                  <p className="text_pie_two">     
                  9993 98 06 29<br/>
                  9994 87 14 62<br/>
                  </p>
              </div>        
              <div className="col">
                  <p className="text_pie">SIGUENOS EN:</p>
                  <p>
                      {/* <!-- Facebook --> */}
                      <a href="https://www.facebook.com/NextLevelArcades" className="scmediacolor">
                          <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.instagram.com/next_levelarcades/?hl=es" className="scmediacolor">
                          {/* <!-- Instagram --> */}
                          <i className="fab fa-instagram"></i>
                      </a>
                      <a href="http://wa.me/5219993980629" className="scmediacolor">
                          {/* <!-- Whatsapp --> */}
                          <i className="fab fa-whatsapp"></i>
                      </a>
                      <a href="http://tiktok.com/@next_levelyou" className="scmediacolor">
                          {/* <!-- Tiktok --> */}
                          <i className="fab fa-tiktok"></i>
                      </a>
                  </p>
                  <p className="text_pie_copy">Next Level Arcades &copy;</p>

              </div>
          </div>
      </div>);
}

export default Footer;