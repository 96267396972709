import React from 'react';
import logo from './logo.svg';
import './App.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { Route, Routes } from 'react-router-dom';
import Item from './components/Item';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { Menubar } from 'primereact/menubar';
import Header from './layout/header';
import Footer from './layout/footer';
import GenericPage from './components/builder/generic_page';
import Container, { ContainerModel } from './components/builder/children/Container';
import ChildrenType from './components/builder/enums/children_type';

function App() {

  const testModelCustom: ContainerModel = {
    child: {class: 'flex flex-column', value: 'TEXTO'},
    type: ChildrenType.Custom
  }

  const testModel: ContainerModel = {
    child: {class: 'flex flex-column', value: 'TEXTO'},
    type: ChildrenType.Empty
  }

  const testModelEx: ContainerModel = {
    child: {class: 'flex flex-column', value: 'TEXTO'},
    type: ChildrenType.Text
  }

  const testImage: ContainerModel = {
    child: {class: 'w-2', src: '/assets/images/logos/logo.png', alt: 'Imagen TEST'},
    type: ChildrenType.Image
  }

  const testModelTEx: ContainerModel = {
    child: {class: 'flex flex-column surface-200 w-full', text: 'Titulo', description: 'Descripcion'},
    type: ChildrenType.TextAndDescription
  }

  const testSectionModel: any = {
    sections: [
      {
        sizeWidget: 12,
        children: [
          testModelCustom,
          {
            child: {class: 'flex flex-column surface-200 w-full', text: 'Titulo', description: 'Descripcion'},
            type: ChildrenType.TextAndDescription
          },
          testImage,
          testModelCustom,
        ]
      },
    ]
  }

  return (<>
    <PrimeReactProvider>
      <Header />
      <div className="App cont">
        <Routes>
          <Route
            path="/"
            element={<GenericPage title={"Inicio"} model={testSectionModel} />}
          />
          <Route
            path="/store"
            element={<Item searchTerm={"Tienda"} />}
          />
          <Route
            path="/contacto"
            element={<Item searchTerm={"contacto"} />}
          />
          <Route path="/mayoristas" element={<Item searchTerm={"mayoristas"} />} />
          <Route
            path="/store/search/:searchInput"
            element={<Item />}
          />
          {/* <Route path='*' element={<NotFound />} /> */}
        </Routes>
      </div>

      <Footer />
    </PrimeReactProvider></>
  );
}

export default App;
