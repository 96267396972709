enum ChildrenType {
    Text,
    Description,
    TextAndDescription,
    Image,
    ImageAndDescription,
    Container,
    Section,
    Empty,
    Custom
}

export default ChildrenType;