import { Menubar } from "primereact/menubar";
import { Link, useNavigate } from 'react-router-dom';

const Header = () => { 
    const navigate = useNavigate()
    const items = [
        {
            label: 'Inicio',
            icon: 'pi pi-fw pi-home',
            className: 'p-menuitem-active',
            command: (event:any) => {
                navigate('/')
            }
        },
        {
            label: 'Tienda',
            icon: 'pi pi-fw pi-shopping-cart',
            className: 'haveItemEx',
            items: [
                {
                    label: 'Inicio',
                    icon: 'pi pi-fw pi-search',
                    command: (event:any) => {
                        navigate('/store')
                    }
                }
            ]
        },
        {
            label: 'Contacto',
            icon: 'pi pi-fw pi-phone',
            url: '/contacto',
            target: '_self'
        },
        {
            label: 'Mayoristas',
            icon: 'pi pi-fw pi-info-circle',
            command: (event:any) => {
                navigate('/mayoristas')
            }
        }
    ];
    const start = (<><div>
      <a href="/">
          <img src="/assets/images/logos/logo1.png" style={{paddingLeft: "8px;"}} width="90" className="d-inline-block align-top" alt="" />
      </a>
  </div></>);
    const end = (<div className="box-top">
    <div><i className="pi pi-whatsapp iconMenu"></i> <span className="text">(+52) 9993980629</span></div>
    <div><i className="pi pi-facebook iconMenu"></i> <span className="text">/NextLevelArcades</span></div>         
  </div>);
    return (
        <div className="menu-cont" style={{minHeight: "60px;"}}>
          <Menubar model={items} start={start} end={end}/>
        </div>);
}
export default Header;