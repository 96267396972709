import { useEffect, useState } from "react";
import { ContainerModel } from "./children/Container";
import Section from "./children/Section";
import ChildrenType from "./enums/children_type";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-webpage';
// import es from 'grapesjs/locale/es';

const GenericPage = (props:any) => {

    const [sections, setSections] = useState(props.model.sections);
    const [visible, setVisible] = useState(false);

    const addWidgetToContainer = (section:number, container:number, type:ChildrenType) => {
        let sectionsEx = [...sections]
        const testModel: ContainerModel = {
          child: {class: 'flex flex-column', value: 'TEXTO'},
          type: type
        }
        sectionsEx[section].children[container] = testModel
        setSections(sectionsEx)
    }

    const addSection = () => {
        const section = {
            sizeWidget: 12,
            children: []
        }
        setSections([...sections, section])
    }

    useEffect(() => {
        var editor  = grapesjs.init({
            container : '#gjs',
            fromElement: true,
            showOffsets: true,
            selectorManager: { componentFirst: true },
            i18n: {
              locale: 'es', // default locale
              detectLocale: true, // by default, the editor will detect the language
              // localeFallback: 'en', // default fallback
            },
            styleManager: {
              sectors: [{
                  name: 'General',
                  properties:[
                    {
                      extend: 'float',
                      type: 'radio',
                      default: 'none',
                      options: [
                        {
                            value: 'none', className: 'fa fa-times',
                            id: ""
                        },
                        {
                            value: 'left', className: 'fa fa-align-left',
                            id: ""
                        },
                        {
                            value: 'right', className: 'fa fa-align-right',
                            id: ""
                        }
                      ],
                    },
                    'display',
                    { extend: 'position', type: 'select' },
                    'top',
                    'right',
                    'left',
                    'bottom',
                  ],
                }, {
                    name: 'Dimension',
                    open: false,
                    properties: [
                      'width',
                      {
                        id: 'flex-width',
                        type: 'integer',
                        name: 'Width',
                        units: ['px', '%'],
                        property: 'flex-basis',
                        toRequire: true,
                      },
                      'height',
                      'max-width',
                      'min-height',
                      'margin',
                      'padding'
                    ],
                  },{
                    name: 'Typography',
                    open: false,
                    properties: [
                        'font-family',
                        'font-size',
                        'font-weight',
                        'letter-spacing',
                        'color',
                        'line-height',
                        {
                          extend: 'text-align',
                          options: [
                            { id : 'left',  label : 'Left',    className: 'fa fa-align-left'},
                            { id : 'center',  label : 'Center',  className: 'fa fa-align-center' },
                            { id : 'right',   label : 'Right',   className: 'fa fa-align-right'},
                            { id : 'justify', label : 'Justify',   className: 'fa fa-align-justify'}
                          ],
                        },
                        {
                          property: 'text-decoration',
                          type: 'radio',
                          default: 'none',
                          options: [
                            { id: 'none', label: 'None', className: 'fa fa-times'},
                            { id: 'underline', label: 'underline', className: 'fa fa-underline' },
                            { id: 'line-through', label: 'Line-through', className: 'fa fa-strikethrough'}
                          ],
                        },
                        'text-shadow'
                    ],
                  },{
                    name: 'Decorations',
                    open: false,
                    properties: [
                      'opacity',
                      'border-radius',
                      'border',
                      'box-shadow',
                      'background', // { id: 'background-bg', property: 'background', type: 'bg' }
                    ],
                  },{
                    name: 'Extra',
                    open: false,
                    buildProps: [
                      'transition',
                      'perspective',
                      'transform'
                    ],
                  },{
                    name: 'Flex',
                    open: false,
                    properties: [{
                      name: 'Flex Container',
                      property: 'display',
                      type: 'select',
                      defaults: 'block',
                      list: [
                        {
                            value: 'block', name: 'Disable',
                            id: ""
                        },
                        {
                            value: 'flex', name: 'Enable',
                            id: ""
                        }
                      ],
                    },{
                      name: 'Flex Parent',
                      property: 'label-parent-flex',
                      type: 'integer',
                    },{
                      name: 'Direction',
                      property: 'flex-direction',
                      type: 'radio',
                      defaults: 'row',
                      list: [{
                          value: 'row',
                          name: 'Row',
                          className: 'icons-flex icon-dir-row',
                          title: 'Row',
                          id: ""
                      },{
                          value: 'row-reverse',
                          name: 'Row reverse',
                          className: 'icons-flex icon-dir-row-rev',
                          title: 'Row reverse',
                          id: ""
                      },{
                          value: 'column',
                          name: 'Column',
                          title: 'Column',
                          className: 'icons-flex icon-dir-col',
                          id: ""
                      },{
                          value: 'column-reverse',
                          name: 'Column reverse',
                          title: 'Column reverse',
                          className: 'icons-flex icon-dir-col-rev',
                          id: ""
                      }],
                    },{
                      name: 'Justify',
                      property: 'justify-content',
                      type: 'radio',
                      defaults: 'flex-start',
                      list: [{
                          value: 'flex-start',
                          className: 'icons-flex icon-just-start',
                          title: 'Start',
                          id: ""
                      },{
                          value: 'flex-end',
                          title: 'End',
                          className: 'icons-flex icon-just-end',
                          id: ""
                      },{
                          value: 'space-between',
                          title: 'Space between',
                          className: 'icons-flex icon-just-sp-bet',
                          id: ""
                      },{
                          value: 'space-around',
                          title: 'Space around',
                          className: 'icons-flex icon-just-sp-ar',
                          id: ""
                      },{
                          value: 'center',
                          title: 'Center',
                          className: 'icons-flex icon-just-sp-cent',
                          id: ""
                      }],
                    },{
                      name: 'Align',
                      property: 'align-items',
                      type: 'radio',
                      defaults: 'center',
                      list: [{
                          value: 'flex-start',
                          title: 'Start',
                          className: 'icons-flex icon-al-start',
                          id: ""
                      },{
                          value: 'flex-end',
                          title: 'End',
                          className: 'icons-flex icon-al-end',
                          id: ""
                      },{
                          value: 'stretch',
                          title: 'Stretch',
                          className: 'icons-flex icon-al-str',
                          id: ""
                      },{
                          value: 'center',
                          title: 'Center',
                          className: 'icons-flex icon-al-center',
                          id: ""
                      }],
                    },{
                      name: 'Flex Children',
                      property: 'label-parent-flex',
                      type: 'integer',
                    },{
                      name: 'Order',
                      property: 'order',
                      type: 'integer',
                      defaults: "0",
                      min: 0
                    },{
                      name: 'Flex',
                      property: 'flex',
                      type: 'composite',
                      properties  : [{
                        name: 'Grow',
                        property: 'flex-grow',
                        type: 'integer',
                        defaults: "0",
                        min: 0
                      },{
                        name: 'Shrink',
                        property: 'flex-shrink',
                        type: 'integer',
                        defaults: "0",
                        min: 0
                      },{
                        name: 'Basis',
                        property: 'flex-basis',
                        type: 'integer',
                        units: ['px','%',''],
                        unit: '',
                        defaults: 'auto',
                      }],
                    },{
                      name: 'Align',
                      property: 'align-self',
                      type: 'radio',
                      defaults: 'auto',
                      list: [{
                          value: 'auto',
                          name: 'Auto',
                          id: ""
                      },{
                          value: 'flex-start',
                          title: 'Start',
                          className: 'icons-flex icon-al-start',
                          id: ""
                      },{
                          value: 'flex-end',
                          title: 'End',
                          className: 'icons-flex icon-al-end',
                          id: ""
                      },{
                          value: 'stretch',
                          title: 'Stretch',
                          className: 'icons-flex icon-al-str',
                          id: ""
                      },{
                          value: 'center',
                          title: 'Center',
                          className: 'icons-flex icon-al-center',
                          id: ""
                      }],
                    }]
                  }
                ],
            },
            plugins: [
              'gjs-blocks-basic',
              'grapesjs-plugin-forms',
              'grapesjs-component-countdown',
              'grapesjs-plugin-export',
              'grapesjs-tabs',
              'grapesjs-custom-code',
              'grapesjs-touch',
              'grapesjs-parser-postcss',
              'grapesjs-tooltip',
              'grapesjs-tui-image-editor',
              'grapesjs-typed',
              'grapesjs-style-bg',
              'grapesjs-preset-webpage',
            ],
            pluginsOpts: {
              'gjs-blocks-basic': { flexGrid: true },
              'grapesjs-tui-image-editor': {
                script: [
                  // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
                  'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
                  'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
                  'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
                ],
                style: [
                  'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
                  'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
                ],
              },
              'grapesjs-tabs': {
                tabsBlock: { category: 'Extra' }
              },
              'grapesjs-typed': {
                block: {
                  category: 'Extra',
                  content: {
                    type: 'typed',
                    'type-speed': 40,
                    strings: [
                      'Text row one',
                      'Text row two',
                      'Text row three',
                    ],
                  }
                }
              },
              'grapesjs-preset-webpage': {
                modalImportTitle: 'Import Template',
                modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
                modalImportContent: function(editor: { getHtml: () => string; getCss: () => string; }) {
                  return editor.getHtml() + '<style>'+editor.getCss()+'</style>'
                },
              },
            },
          });
    
          editor.I18n.addMessages({
            en: {
              styleManager: {
                properties: {
                  'background-repeat': 'Repeat',
                  'background-position': 'Position',
                  'background-attachment': 'Attachment',
                  'background-size': 'Size',
                }
              },
            }
          });
    }, []);

    
    return (<><div className="flex flex-column w-12" id="gjs">
        </div>
    </>);
}

export default GenericPage;